const apiUrl = '/api/auth'; // 'http://localhost:4000'; // Replace with your backend URL

class ClientAuth {
	storageKey = 'auth';

	data( newblob ) {
		if ( newblob ) {
			localStorage.setItem( this.storageKey, JSON.stringify( newblob ) );
		}

		const val = localStorage.getItem( this.storageKey );
		return val ? JSON.parse( val ) : {};
	}

	exists() {
		return localStorage.getItem( this.storageKey ) ? true : false; //NOSONAR
	}

	clear() {
		localStorage.removeItem( this.storageKey );
	}

	get(key) {
		return this.data()[key];
	}

	set(key, value) {
		const data = this.data();
		data[key] = value;
		this.data( data );
	}

}

const authdata = new ClientAuth();

const authProvider = {
	login: async ( { username, password } ) => {

		const request = new Request(`${apiUrl}/login`, {
			method: 'POST',
			body: JSON.stringify({ username, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		return fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(auth => {

				authdata.data( auth );
				return Promise.resolve();
			});
	},

	logout: async () => {
		authdata.clear();
		return Promise.resolve();
	},

	checkError: async ({status}) => {
		if ( status === 401 || status === 403 ) {
			authdata.clear();
			return Promise.reject( new Error( status ) );
		}

		return Promise.resolve();
	},

	checkAuth: async () => { return authdata.exists() ? Promise.resolve() : Promise.reject( new Error() ) },
	getIdentity: async () => authdata.data(),
	getPermissions: async () => authdata.get('role'),
	canAccess: async ({ action, resource }) => {
		const roles = authdata.get('role');
		return ( -1 === roles.indexOf( 'admin' ) ) ? false : true; //NOSONAR
	}

};

export default authProvider;
