import { List, Datagrid, TextField, EditButton, CreateButton, TopToolbar } from 'react-admin';

const ListActions = () => (
	<TopToolbar>
		<CreateButton />
	</TopToolbar>
);


const list = () => (
	<List
		actions={<ListActions />}
		exporter={false}
		>
		<Datagrid rowClick="edit">
			<TextField source="name" />
			<EditButton />
		</Datagrid>
	</List>
);

export default list;
