import { Create, SimpleForm, TextInput, DateInput, ReferenceInput, SelectInput, required } from 'react-admin';
import { Typography } from '@mui/material';
import { voiceoverOptionText, StatusChoices, DefaultValues } from './meta';

const EventCreate = (props) => (

	<Create {...props}>
		<SimpleForm defaultValues={DefaultValues}>
			<TextInput source="name" validate={required()} />
			<DateInput source="start_date" fullWidth={false} />
			<ReferenceInput source="persona_id" reference="personas">
				<SelectInput optionText="name" validate={required()} fullWidth={false} />
			</ReferenceInput>
			<SelectInput source="status" choices={StatusChoices} validate={required()} fullWidth={false} />

			<ReferenceInput source="pre_voiceover_id" reference="voiceovers" label="Pre-experience Voiceover">
				<SelectInput label="Pre-Experience Voiceover/Text" optionText={ voiceoverOptionText }/>
			</ReferenceInput>

			<Typography>Topic 1</Typography>
			<TextInput source="topic1_title" label="Title" validate={required()} />
			<TextInput source="topic1_description" label="Description" multiline validate={required()} />

			<Typography>Topic 2</Typography>
			<TextInput source="topic2_title" label="Title" />
			<TextInput source="topic2_description" label="Description" multiline />

			<Typography>Topic 3</Typography>
			<TextInput source="topic3_title" label="Title" />
			<TextInput source="topic3_description" label="Description" multiline />
		</SimpleForm>
	</Create>
);

export default EventCreate;
