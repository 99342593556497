import create from './create';
import list from './list';
import edit from './edit';

export const Voiceover = {
	create,
	list,
	edit
};

export default Voiceover;