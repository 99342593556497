//import logo from './logo.svg';
import './App.css';

import { Admin, Resource } from 'react-admin';
// WithPermissions, usePermissions

//import { Route } from 'react-router-dom';

import dataProvider from './Providers/dataProvider';
import authProvider from './Providers/authProvider';

//import { Dashboard, Settings } from './Pages';
//import { AppLayout } from './Layout';
import PersonIcon from '@mui/icons-material/Person'; // Users
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'; // Participant
import AssignmentIcon from '@mui/icons-material/Assignment'; // PreExperience
//import GroupIcon from '@mui/icons-material/Group'; // Personas
import EventIcon from '@mui/icons-material/Event';

import Event from './Resources/Event';
import Voiceover from './Resources/Voiceover';
import Interview from './Resources/Interview';
//import { PersonaEdit, PersonaList, PersonaCreate } from './Resources/Persona';
import { UserEdit, UserList, UserCreate } from './Resources/User';
import { FormEntryCreate, FormEntryEdit, FormEntryList } from './Resources/FormEntry';


function App() {
	
	return (
		<Admin
			dataProvider={dataProvider}
			authProvider={authProvider}
			disableTelemetry
			requireAuth
			>
			<Resource name="events"  {...Event} icon={EventIcon} />
			<Resource name="form-entries" list={FormEntryList} edit={FormEntryEdit} create={FormEntryCreate} options={{label:"Participants" }} icon={AssignmentIndIcon} />
			<Resource name="interviews" options={{label: "Conversations"}} {...Interview} />
			<Resource name="voiceovers" options={{label: 'Pre-Experience' }} {...Voiceover} icon={AssignmentIcon} />
			{/*
			<Resource name="personas" list={PersonaList} edit={PersonaEdit} create={PersonaCreate} icon={GroupIcon} />
			*/}
			<Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={PersonIcon} />

		</Admin>
	);
}

export default App;
