import { PasswordInput, DateTimeInput, Edit, SelectArrayInput, SimpleForm, TextInput, required } from 'react-admin';

import { RoleChoices } from './meta';

export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="email" validate={required()} />
            <PasswordInput source="new_password" />
            <TextInput source="name" validate={required()} />
            <SelectArrayInput source="role" choices={RoleChoices} fullWidth={false} />
            <DateTimeInput source="last_login" disabled fullWidth={false} />
        </SimpleForm>
    </Edit>
);
export default UserEdit;