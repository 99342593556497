import EventList from './list';
import EventEdit from './edit';
import EventCreate from './create';

/*
export {
	EventList as list,
	EventEdit as edit,
	EventCreate as create
};
*/

export const Event = {
	list: EventList,
	edit: EventEdit,
	create: EventCreate
};

export default Event;