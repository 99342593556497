import { stripHtml } from 'string-strip-html';

export const StatusChoices = [
	{ id: 'active', name: 'Active' },
	{ id: 'inactive', name: 'Inactive' }
];

export const DefaultValues = {
	"status": "active",
	"persona_id": 1
};

export const voiceoverOptionText = r => {
	let { name, content } = r;
	let label = name;
	content = stripHtml( content ).result;

	if ( content ) {
		const contentFrag = content.length > 30 ? content.substring( 0, 30 ) + '...' : content;
		label += ' - ' + contentFrag;
	}
	
	return label;
};
