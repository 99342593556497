//import { ShowGuesser as show } from "react-admin";
import { DateField, ReferenceField, Show, SimpleShowLayout, TextField, RichTextField, List, Datagrid,
    ReferenceInput, AutocompleteInput
 } from 'react-admin';

export const show = () => (
    <Show>
        <SimpleShowLayout>
            <ReferenceField source="event_id" reference="events" />
            <TextField source="name" />
            <TextField source="company" />
            <TextField source="role" />
            <DateField source="createdAt" showDate showTime />
            <DateField source="updatedAt" showDate showTime />
            <RichTextField source="transcript_formatted" label="Transcript" />
        </SimpleShowLayout>
    </Show>
);

const eventFilterQuery = searchText => ({ name: `%${searchText}%` });

const formFilters = [
	<ReferenceInput label="Events" source="event_id" reference="events" alwaysOn>
		<AutocompleteInput source="name" filterToQuery={eventFilterQuery} />
	</ReferenceInput>
];

export const list = () => (
    <List
        exporter={false}
        filters={formFilters}
        >
        <Datagrid>
            <ReferenceField source="event_id" reference="events" />
            <TextField source="name" />
            <TextField source="company" />
            <TextField source="role" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);


const Interview = {
	show,
    list
};

export default Interview;