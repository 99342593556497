import React from 'react';
import { Create, SimpleForm, TextInput, FileInput, required } from 'react-admin';
import { AudioField } from '../../components/AudioField';

const RichTextInput = React.lazy(() =>
	import('ra-input-rich-text').then( module => ({
		default: module.RichTextInput
	}))
);


const create = (props) => (

	<Create {...props}>
		<SimpleForm>
			<TextInput source="name" validate={required()} />
			<RichTextInput source="content" fullWidth  />
			<FileInput source="audio" accept={{ 'audio/*': [ '.mp3' ] }}>
				<AudioField source="src" title="title"  />
			</FileInput>
		</SimpleForm>
	</Create>
);

export default create;
